import React from "react";

const Page = () => (
  <div class="note-wrapper">
    <h1>Privacy Policy</h1>
    <p>
      This page is used to inform visitors regarding my policies with the
      collection, use, and disclosure of Personal Information if anyone decided
      to use our App.
    </p>
    <br />
    <h2>Information Collection</h2>
    <p>
      To help improve the App, anonymous usage statistics are collected via
      <a href="https://policies.google.com/privacy?hl=de">
        Firebase Analytics
      </a>{" "}
      . Those statistics include information about App interactions like
      clicking on menu entries, opening dialogs, the timestamp of the
      interactions, geo-location data, device id etc.
    </p>
    <br />
    <p>
      App errors are collected via
      <a href="https://try.crashlytics.com/terms/privacy-policy.pdf">
        Crashlytics
      </a>{" "}
      . The error log does not contain any information which could be used to
      find out the identity of the user. It contains only information needed
      during the error fixing process.
    </p>
    <br />
    <p>
      <i>My Time</i> will sync user data with iCould.
    </p>
    <br />
    <h2>Security</h2>
    <p>
      <i>My Time</i> does not collects personal identifiable information, login
      credentials, contacts etc.
    </p>
    <br />
    <p>
      <i>My Time</i> does not shares your data with anyone without your
      permission, legal requirements excluded.
    </p>
    <br />
    <h2>Changes to This Privacy Policy</h2>
    <p>
      We may update our Privacy Policy from time to time. Thus, you are advised
      to review this page periodically for any changes. We will notify you of
      any changes by posting the new Privacy Policy on this page. These changes
      are effective immediately after they are posted on this page.
    </p>
    <br />
    <h2>Contact Us</h2>
    <p>
      If you have any questions or suggestions about my Privacy Policy, do not
      hesitate to contact us at
      <a href="mailto:support@fxc.ninja">support@fxc.ninja</a>.
    </p>
  </div>
);

export default Page;
